<ng-container *ngVar="(actionRD$ | async)?.payload as workflowAction">
  <div class="mt-1 mb-3 space-children-mr">
    <ds-claimed-task-actions-loader *ngFor="let option of workflowAction?.options" [option]="option" [object]="object"
      (processCompleted)="this.processCompleted.emit($event)">
    </ds-claimed-task-actions-loader>

    <button class="btn btn-primary workflow-view" ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
      [routerLink]="[getWorkflowItemViewRoute((workflowitem$ | async))]">
      <i class="fa fa-info-circle"></i> {{"submission.workflow.generic.view" | translate}}
    </button>

    <ds-claimed-task-actions-loader [option]="returnToPoolOption" [object]="object"
      (processCompleted)="this.processCompleted.emit($event)">
    </ds-claimed-task-actions-loader>
  </div>
</ng-container>
